import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Container, Row, Col } from "reactstrap"
import { config } from "../../lib/config"
import { externalLinks } from "../../lib/external-links"
import { Locales } from "../../lib/types/shared"
import { Image } from "../shared/image"

const DefaultFooter = () => {
  const intl = useIntl()
  const locale = intl.locale as Locales
  return (
    <footer className="bg-white text-center text-md-left">
      <Container>
        <Row className="py-3 py-md-5">
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <a
                  className="text-muted"
                  href={config.urls.homepage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="default.footer.homepage"
                    defaultMessage="Homepage"
                  />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={externalLinks[locale].howItWorks}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="default.footer.howItWorks"
                    defaultMessage="How it works"
                  />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={externalLinks[locale].support}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="default.footer.support"
                    defaultMessage="Support"
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <a
                  className="text-muted"
                  href={externalLinks[locale].about}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="default.footer.about"
                    defaultMessage="About"
                  />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={externalLinks[locale].FAQ}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="default.footer.faq"
                    defaultMessage="FAQ"
                  />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={externalLinks[locale].blog}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="default.footer.blog"
                    defaultMessage="Blog"
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <a
                  className="text-muted"
                  href={externalLinks[locale].terms}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="default.footer.terms"
                    defaultMessage="Terms"
                  />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={externalLinks[locale].privacy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="default.footer.privacy"
                    defaultMessage="Privacy"
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3" className="text-md-right">
            <Image
              height="78"
              width="100"
              src="/web-catalog-app/assets/mb-logo-footer.svg"
              alt="Moberries Logo"
            />
          </Col>
        </Row>
        <hr className="m-0 border-top" />
        <Row className="py-3 py-md-4">
          <Col xs="12" md="4">
            <span className="font-weight-light">
              <FormattedMessage
                id="default.footer.copyright"
                defaultMessage="© MoBerries {year}"
                values={{
                  year: config.package.buildYear,
                }}
              />
            </span>
          </Col>
          <Col xs="12" md="8" className="text-md-right">
            <ul className="list-inline m-0">
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.facebook.com/moberries/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://twitter.com/MoBerries"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.linkedin.com/company/10009040"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.youtube.com/channel/UC22gC5eRgLqqnVg9zjSI53w"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="text-muted"
                  href="https://www.instagram.com/teammoberries/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default DefaultFooter
