import { config } from "./config"

const homepageUrl = config.urls.homepage
const helpUrl = config.urls.help

export const externalLinks = {
  en: {
    homePage: homepageUrl,
    howItWorks: `${homepageUrl}/howto`,
    howToCv: `${homepageUrl}/howto-cv`,
    support: "mailto:support@moberries.com",
    chatbot: "https://m.me/moberries",
    about: `${homepageUrl}/about`,
    FAQ: `${helpUrl}/en/`,
    terms: `${homepageUrl}/terms`,
    privacy: `${homepageUrl}/privacy`,
    blog: `${homepageUrl}/blog`,
    htgfHome: "https://htgf.de/en/",
    redstoneSectorFunds: "https://www.redstone.vc/sector-funds/",
    redstoneCorporateVenturing: "https://www.redstone.vc/corporate-venturing/",
    redstoneTeam: "https://www.redstone.vc/team/",
    redstoneContact: "https://www.redstone.vc/contact/",
  },
  de: {
    homePage: `${homepageUrl}/de`,
    howItWorks: `${homepageUrl}/de/howto`,
    howToCv: `${homepageUrl}/de/howto-cv`,
    support: "mailto:support@moberries.com",
    chatbot: "https://m.me/moberries",
    about: `${homepageUrl}/de/about`,
    FAQ: `${helpUrl}/de/`,
    terms: `${homepageUrl}/de/terms`,
    privacy: `${homepageUrl}/de/privacy`,
    blog: `${homepageUrl}/de/blog`,
    htgfHome: "https://htgf.de/de/",
    redstoneSectorFunds: "https://www.redstone.vc/de/sektor-fonds/",
    redstoneCorporateVenturing:
      "https://www.redstone.vc/de/corporate-venturing-2/",
    redstoneTeam: "https://www.redstone.vc/de/team-2/",
    redstoneContact: "https://www.redstone.vc/de/kontakt/",
  },
}
